<script setup lang="ts">
import {
	BaseFetchWrapper,
	BaseToggle,
	useAsyncState,
} from '@legacy-studio/library';
import { useProfile } from '../../../infrastructure/store';
import { useChangePartnerDisablePayment } from '~/src/features/profile/core/application/updatePartnerPayment';

const user = useProfile();

const { isLoading, execute } = useAsyncState(useChangePartnerDisablePayment());

const onChange = () => {
	execute(!user.value?.disablePayments);
};
</script>

<template>
	<BaseFetchWrapper :pending="isLoading">
		<div class="border-t border-gray-600 flex gap-8 justify-between pt-8">
			<div class="flex flex-col gap-1">
				<span class="text-xl font-bold text-white">Я хочу приостановить выплаты</span>
				<p class="text-base font-normal text-gray-400">
					Приостановка выплат позволяет партнеру сохранить свои накопленные средства в системе партнерской программы и вывести целиком когда будет необходимо
				</p>
			</div>

			<BaseToggle
				:model-value="user?.disablePayments"
				@update:model-value="onChange"
			/>
		</div>
	</BaseFetchWrapper>
</template>
