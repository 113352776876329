import { useApi } from '@/src/services/network';
import type { EditPersonalPartnerForm } from '@/src/features/profile/types';
import { type PartnerProfile, toDomainPartner } from '~/src/features/profile/infrastructure/api/getPartnerProfile';

const fromDomainPersonal = (payload: EditPersonalPartnerForm): any => {
	return {
		name: payload.firstName,
		last_name: payload.lastName,
		phone_number: payload.phone,
		country: payload.country,
		skype: payload.skype,
		telegram: payload.telegram,
	};
};

export const useUpdatePartnerPersonal: PartnerProfile = async (payload: EditPersonalPartnerForm) => {
	const result = await useApi()('/Partner/update/', {
		method: 'POST',
		body: fromDomainPersonal(payload),
	});
	return result.mapRight(toDomainPartner);
};
