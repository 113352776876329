import { apiMethods } from '../../infrastructure/api';
import {
	readActiveInvoice,
	readInvoices,
	saveActiveInvoice,
	saveInvoices,
} from '../../infrastructure/store';
import { notificationBus } from '~/src/features/app/infrastructure/bus';

export const useRefreshDemoInvoice = () => {
	const refreshDemoInvoice = apiMethods.useRefreshDemoInvoice();
	const triggerNotificationError = notificationBus.useOnTriggerNotificationError();

	return async () => {
		const result = await refreshDemoInvoice();

		if (result.isRight()) {
			const updatedDemoInvoice = result.value;
			const invoices = readInvoices();
			const invoicesWithoutDemo = invoices.filter(
				item => item.id !== updatedDemoInvoice.id,
			);

			saveInvoices([updatedDemoInvoice, ...invoicesWithoutDemo]);

			const activeInvoice = readActiveInvoice();

			if (activeInvoice?.id === updatedDemoInvoice.id) {
				saveActiveInvoice(updatedDemoInvoice);
			}
		}
		else {
			const errorMessage = result.value.message;
			triggerNotificationError(errorMessage);
		}
	};
};
