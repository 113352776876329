import { useApi } from '@/src/services/network';
import type {
	Response, USER_ACCOUNT_TYPE, UserAccountType, UserColorCode, type UserGroupId, UserId,
} from '~/src/shared/lib';
import { getUserGroupId, type UserGroupsDTO } from '~/src/features/user/infrastructure/api/getUser';
import type { FileDTO, FilesDTO } from '~/src/features/profile/infrastructure/api/addTraderVerification';

export interface TraderProfile {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	photo: string;
	phone: string;
	language: string;
	group: UserGroupsDTO;
	registrationDate: DateString;
	referralCode: string;
	accountType: UserAccountType;
	login: string;
	color: UserColorCode;
	groupId: UserGroupId;
	userVerified: null | '1';
	verification: {
		id: string;
		user: string;
		status: {
			id: string;
			name: string;
			code: string;
		};
		dateCreate: string;
		dateConfirm: string;
		rejectionReason: string;
		files: string[];
	};
	country: {
		id: string;
		code: string;
		name: {
			ru: string;
			en: string;
		};
		phonePref: string;
	} | null;
	phoneVerified: boolean;
	emailVerified: boolean;
	gender: string;
	birthDate: DateString;
	citizenship: string;
	city: string;
	address: string;
	jwt: string | null;
}

export type TraderProfileDTO = Response<{
	id: UserId;
	login: string;
	email: string;
	name: string;
	last_name: string;
	photo: string;
	phone_number: string;
	color: UserColorCode;
	language: string;
	user_verified: string;
	group: UserGroupsDTO;
	date_register: DateString;
	referal_code: string;
	verification: {
		id: string;
		user: string;
		status: {
			id: string;
			name: string;
			code: string;
		};
		date_create: string;
		date_confirm: string;
		rejection_reason: string;
		files: FilesDTO;
	};
	country: {
		id: string;
		code: string;
		name: {
			ru: string;
			en: string;
		};
		phone_pref: string;
	} | null;
	tariff: {
		id: string;
		name: string;
		code: UserAccountType;
	};
	phone_verified: '0' | '1';
	email_verified: '0' | '1';
	gender: string;
	birth_date: string;
	citizenship: string;
	city: string;
	address: string;
	jwt: string | null;
}>;

export const toDomainTrader = (user: TraderProfileDTO): TraderProfile => {
	const trader = {
		id: user.result.id,
		login: user.result.login,
		email: user.result.email,
		firstName: user.result.name,
		lastName: user.result.last_name,
		photo: user.result.photo,
		phone: user.result.phone_number,
		colorCode: user.result.color || '1',
		accountType: user.result.tariff?.code ?? USER_ACCOUNT_TYPE.free,
		groupId: getUserGroupId(user.result.group),
		language: user.result.language,
		registrationDate: user.result.date_register,
		referralCode: user.result.referal_code,
		country: user.result.country
			? {
					id: user.result.country.id,
					code: user.result.country.code,
					name: {
						ru: user.result.country.name.ru,
						en: user.result.country.name.en,
					},
					phonePref: user.result.country.phone_pref,
				}
			: null,
		userVerified: user.result.user_verified,
		verification: user.result.verification
			? {
					id: user.result.verification.id,
					user: user.result.verification.id,
					status: {
						id: user.result.verification.status.id,
						name: user.result.verification.status.name,
						code: user.result.verification.status.code,
					},
					dateCreate: user.result.verification.date_create,
					dateConfirm: user.result.verification.date_confirm,
					rejectionReason: user.result.verification.rejection_reason,
					files: Object.values(user.result.verification.files as FilesDTO).map((file: FileDTO) => file.path),
				}
			: null,
		phoneVerified: Boolean(+user.result.phone_verified),
		emailVerified: Boolean(+user.result.email_verified),
		gender: user.result.gender,
		birthDate: user.result.birth_date,
		citizenship: user.result.citizenship,
		city: user.result.city,
		address: user.result.address,
		jwt: user.result.jwt,
	};
	return trader;
};

export const useGetTraderProfile = () => {
	const api = useApi();

	return async () => (await api<TraderProfileDTO>('/api/Trader/getProfile/')).mapRight(toDomainTrader);
};
