import { TokenService } from '../domain';
import { useOpenIndexPage } from '~/src/shared/lib';
import { useClearUser } from '@/src/features/user';

export const useLogout = () => {
	const clearUser = useClearUser();
	const openIndexPage = useOpenIndexPage();

	return () => {
		clearUser();
		TokenService.clear();
		openIndexPage();
	};
};
