<script setup lang="ts">
import {
	BaseCard,
	BaseCardTitle,
	BaseSelect,
	useAsyncState,
} from '@legacy-studio/library';
import { useChangeLocale } from '../../core/application';
import { useProfile } from '~/src/features/profile/infrastructure/store';
import FlagLogo from '~/src/shared/ui/FlagLogo.vue';

const user = useProfile();

const userLocale = computed(() => user.value?.language);

const i18n = useI18n();
const locales = computed(() => i18n.availableLocales);
const items = computed(() =>
	locales.value.map(locale => ({
		name: locale, // TODO
		code: locale,
	})),
);

const { isLoading, execute } = useAsyncState(useChangeLocale());
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Язык интерфейса" />

		<BaseSelect
			:model-value="userLocale"
			:items="items"
			size="regular"
			label="Выберите язык интерфейса"
			direction="top"
			@update:model-value="execute"
		>
			<template #default>
				<div class="flex gap-2 items-center">
					<FlagLogo
						:size="'xs'"
						:code="userLocale"
					/>
					{{ userLocale }}
				</div>
			</template>
			<template #item="{ item }">
				<div class="flex gap-2 items-center">
					<FlagLogo
						:size="'xs'"
						:code="item.code"
					/>
					{{ item.name }}
				</div>
			</template>
		</BaseSelect>
	</BaseCard>
</template>
