<script setup lang="ts">
import {
	BaseCard,
	BaseCardTitle,
	BaseModal, BaseModalCard, BaseModalCardContent,
	BaseToggle,
	useAsyncState,
} from '@legacy-studio/library';
import { closeBlockAccountModal, openBlockAccountModal, useBlockAccount } from '../../core/application';
import { useIsBlockAccountModalOpen } from '../../infrastructure/store';
import { UiDarkBBButton } from '~/src/shared/ui';

const isBlockAccountModalOpen = useIsBlockAccountModalOpen();

const onBlockProfile = () => {
	openBlockAccountModal();
};

const { isLoading, execute } = useAsyncState(useBlockAccount());

const localBlockModel = computed({
	get() {
		return isBlockAccountModalOpen.value;
	},

	set() {
		closeBlockAccountModal();
	},
});
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Настройки аккаунта" />

		<div class="flex gap-8 justify-between">
			<div class="flex flex-col gap-1">
				<p class="text-lg font-semibold text-white">
					Временное закрытие аккаунта
				</p>

				<p class="text-base font-normal text-gray-400">
					Вы можете закрыть аккаунт временно. После закрытия вы не сможете войти
					в личный кабинет и совершать транзакции. Заново открыть аккаунт можно,
					обратившись в службу поддержки.
				</p>
			</div>

			<BaseToggle
				:model-value="isBlockAccountModalOpen ? true : false"
				@update:model-value="onBlockProfile"
			/>

			<BaseModal
				v-model="localBlockModel"
				size="md"
				@close="closeBlockAccountModal"
			>
				<BaseModalCard
					class="max-w-[355px] md:max-w-[480px] "
				>
					<BaseModalCardContent>
						<section class="flex flex-col gap-4">
							<span class="text-2xl font-bold text-white text-center">
								Вы действительно хотите заблокировать аккаунт?
							</span>
							<span class="text-base font-normal text-center text-gray-400">
								После закрытия вы не сможете войти в личный кабинет и совершать транзакции. Заново открыть аккаунт можно, обратившись в службу поддержки.
							</span>
							<div class="flex mt-5 gap-4">
								<UiDarkBBButton
									button-text="Подтвердить"
									class="flex-1"
									@click="execute"
								/>
								<UiDarkBBButton
									:outline="true"
									button-text="Отмена"
									class="flex-1"
									@click="closeBlockAccountModal"
								/>
							</div>
						</section>
					</BaseModalCardContent>
				</BaseModalCard>
			</BaseModal>
		</div>
	</BaseCard>
</template>
