<script setup lang="ts">
import { BaseCard, BaseCardTitle } from '@legacy-studio/library';
import VerificationCenterEmail from './Email.vue';
import VerificationCenterPersonal from './Personal.vue';
import VerificationCenterPersonality from './Personality.vue';
import { useProfile } from '~/src/features/profile/infrastructure/store';
import { UiVerificationBadge } from '~/src/shared/ui';

const traderProfile = useProfile();
const status = computed(() => {
	if (traderProfile.value?.verification) {
		return traderProfile.value?.verification.status.code === 'verified' ? 'verified' : 'unverified';
	}
	else {
		return 'unverified';
	}
});
</script>

<template>
	<BaseCard>
		<BaseCardTitle title="Центр верификации">
			<template #after>
				<UiVerificationBadge :status="status" />
			</template>
		</BaseCardTitle>

		<div class="flex flex-col">
			<VerificationCenterEmail class="pb-8 border-b border-gray-600" />

			<VerificationCenterPersonal class="py-8 border-b border-gray-600" />

			<VerificationCenterPersonality class="pt-8" />
		</div>
	</BaseCard>
</template>
