<script setup lang="ts">
import { BaseBadge, type BaseIconName } from '@legacy-studio/library';

const props = defineProps<{
	status: string; // TOOD
}>();

const iconName = computed<BaseIconName>(() => {
	switch (props.status) {
		case 'verified':
			return 'base--fill--check-circle';
		default:
			return 'base--fill--exclamation-circle';
	}
});

const verificationText = computed(() => {
	switch (props.status) {
		case 'verified':
			return 'Верифицирован';
		case 'unverified':
			return 'Не подтвержден';
		default:
			return 'В обработке';
	}
});

const badgeType = computed(() => {
	switch (props.status) {
		case 'verified':
			return 'green';
		case 'unverified':
			return 'red';
		default:
			return 'red';
	}
});
</script>

<template>
	<div
		class="rounded-md"
		:class="[status === 'verified' ? 'bg-green-900' : 'bg-red-900']"
	>
		<BaseBadge
			:icon="iconName"
			:color="badgeType"
			:text="verificationText"
		/>
	</div>
</template>
