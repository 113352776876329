import { apiMethods } from '../../infrastructure/api';
import { saveIsPersonalityEdit, saveTraderProfileVerification } from '../../infrastructure/store';

export const useAddTraderVerification = () => {
	const addTraderVerification = apiMethods.useAddTraderVerification();

	return async (files: File[]) => {
		const result = await addTraderVerification(files);
		if (result.isRight()) {
			saveTraderProfileVerification(result.value);
		}
		else {
			throw result.value;
		}
	};
};

export const setPersonalityEdit = () => {
	saveIsPersonalityEdit(true);
};

export const setPersonalityNotEdit = () => {
	saveIsPersonalityEdit(false);
};
