import { apiMethods } from '../../infrastructure/api';
import { saveProfile } from '../../infrastructure/store';
import type { EditPersonalPartnerForm } from '../../types';
import { setPersonalNotEdit } from '~/src/features/profile';

export const useUpdatePartnerPersonal = () => {
	const updateProfile = apiMethods.useUpdatePartnerPersonal;

	return async (form: EditPersonalPartnerForm) => {
		const result = await updateProfile(form);
		if (result.isRight()) {
			saveProfile(result.value);
			setPersonalNotEdit();
		}
		else {
			throw result.value;
		}
	};
};
