<script setup lang="ts">
import {
	BaseTextField,
	BaseSelect,
	useAsyncState,
	BaseButton,
	getTextFieldBinds,
	BaseFetchWrapper,
	getSelectBinds,
	BaseDatePicker,
} from '@legacy-studio/library';
import { useForm } from 'vee-validate';
import { editPersonalTraderSchema } from '../../../../core/validation';
import {
	setPersonalEdit,
	setPersonalNotEdit,
	useUpdateTraderPersonal,
} from '../../../../core/application';
import type { EditPersonalTraderForm } from '../../../../types';

import { useIsPersonalEdit } from '../../../../infrastructure/store';
import EditForm from '../../Form.vue';
import FindCityField from '../../FindCityField.vue';
import {
	useSharedDataCountries,
	useSharedDataGetCountries,
} from '~/src/features/shared-data';
import { useGenders } from '~/src/shared/lib';
import {
	UiVerificationLabel,
} from '~/src/shared/ui';
import { useProfile } from '~/src/features/profile/infrastructure/store';
import type { TraderProfile } from '~/src/features/profile/infrastructure/api/getTraderProfile';
import FlagLogo from '~/src/shared/ui/FlagLogo.vue';

const user = useProfile() as ComputedRef<TraderProfile>;
const isPersonalEdit = useIsPersonalEdit();

const isRequiredFields = computed(() => {
	if (!user.value) {
		return false;
	}

	return (
		!!user.value?.firstName
		&& !!user.value?.lastName
		&& !!user.value?.gender
		&& !!user.value?.birthDate
		&& !!user.value?.country?.code
		&& !!user.value?.city
		&& !!user.value?.address
		&& !!user.value?.citizenship
	);
});

const canBeEdited = computed(() => {
	return !user.value?.verification || !isRequiredFields.value;
});

const status = computed(() => {
	if (isRequiredFields.value
		&& user.value?.verification?.status?.code === 'verified') {
		return 'verified';
	}
	if (user.value?.verification?.status?.code === 'rejected') {
		return 'unverified';
	}
	return 'pending';
});

const verificationText = computed(() => {
	if (!isRequiredFields.value) {
		return 'Данные: Не предоставлены';
	}
	else if (user.value?.verification?.status?.code === 'verified') {
		return 'Предоставлены: Подтверждены';
	}
	else if (user.value?.verification?.status?.code === 'rejected') {
		return 'Предоставлены: Не подтверждены';
	}
	else if (user.value?.verification?.status?.code === 'waiting') {
		return 'Предоставлены: Ожидают подтверждения';
	}
	else if (isRequiredFields.value) {
		return 'Предоставлены: Ожидают подтверждения';
	}

	return '';
});

await useSharedDataGetCountries()();
const genders = useGenders();
const countries = useSharedDataCountries();
const countriesRu = computed(() => countries.value.map((item) => {
	return {
		countryCode: item.code,
		name: item.name.ru,
		code: item.id,
	};
}));
const currentCountry = computed(() =>
	country.value
		? countriesRu.value.find(item => item.code === country.value)
		: null,
);

const { defineField, handleSubmit, setValues } = useForm<EditPersonalTraderForm>({
	validationSchema: editPersonalTraderSchema,
	initialValues: user.value
		? {
				firstName: user.value.firstName,
				lastName: user.value.lastName,
				gender: user.value.gender,
				birthday: [user.value.birthDate, null] as any, // TOOD: ?
				phone: user.value.phone ?? '',
				country: (user.value.country as any)?.id, // TOOD: ?
				citizenship: user.value.citizenship || '',
				city: user.value.city,
				address: user.value.address ?? '',
			}
		: {},
});

const [firstName, firstNameProps] = defineField('firstName', getTextFieldBinds);
const [lastName, lastNameProps] = defineField('lastName', getTextFieldBinds);
const [_email, _emailProps] = defineField('email', getTextFieldBinds);
const [gender, genderProps] = defineField('gender', getSelectBinds);
const [phone, phoneProps] = defineField('phone', getTextFieldBinds);
const [birthday, birthdayProps] = defineField('birthday', getTextFieldBinds);
const [country, countryProps] = defineField('country', getSelectBinds);
const [_city, cityProps] = defineField('city', getTextFieldBinds);
const [citizenship, citizenshipProps] = defineField('citizenship', getTextFieldBinds);
const [address, addressProps] = defineField('address', getTextFieldBinds);

watch(country, () => setValues({ city: '' }));

const { execute, isLoading } = useAsyncState(useUpdateTraderPersonal());

const onSubmit = handleSubmit(execute);
</script>

<template>
	<BaseFetchWrapper
		class="relative flex flex-col gap-4"
		:pending="isLoading"
	>
		<div class="flex flex-col justify-between gap-4">
			<p class="text-base font-semibold text-white">
				Персональные данные
			</p>
			<div class="flex flex-col gap-4 md:flex-row items-center justify-between md:gap-[30px]">
				<UiVerificationLabel
					:status="status"
					:title="verificationText"
					subtitle="Ваша информация будет храниться в строгой конфиденциальности."
				/>

				<BaseButton
					v-if="!isPersonalEdit && canBeEdited"
					color="alternative-dark"
					size="xs"
					button-text="Изменить"
					outline
					class="w-full md:w-auto"
					@click="setPersonalEdit"
				/>
			</div>
		</div>

		<EditForm
			v-if="isPersonalEdit"
			@submit="onSubmit"
			@cancel="setPersonalNotEdit"
		>
			<BaseTextField
				v-model="firstName"
				v-bind="firstNameProps"
				placeholder="Введите имя по паспорту"
				label="Имя"
			/>

			<BaseTextField
				v-model="lastName"
				v-bind="lastNameProps"
				placeholder="Введите фамилию по паспорту"
				label="Фамилия"
			/>

			<BaseSelect
				v-model="gender"
				v-bind="genderProps"
				placeholder="Выберите пол"
				label="Пол"
				:items="genders"
			/>

			<BaseDatePicker
				v-model="birthday"
				v-bind="birthdayProps"
				placeholder="Выберите дату"
				label="Дата рождения"
				single
			/>

			<BaseTextField
				v-model="phone"
				v-bind="phoneProps"
				placeholder="Телефон"
				label="Номер телефона"
			/>

			<BaseSelect
				v-model="country"
				v-bind="countryProps"
				placeholder="Выберите страну"
				label="Страна проживания"
				:items="countriesRu"
			>
				<template
					v-if="country"
					#default
				>
					<div class="flex gap-2 items-center">
						<FlagLogo
							:size="'xs'"
							:code="currentCountry?.countryCode"
						/>
						{{ currentCountry?.name }}
					</div>
				</template>
				<template #item="{ item }">
					<div class="flex gap-2 items-center">
						<FlagLogo
							:size="'xs'"
							:code="item?.countryCode"
						/>
						{{ item?.name }}
					</div>
				</template>
			</BaseSelect>

			<BaseTextField
				v-model="citizenship"
				v-bind="citizenshipProps"
				placeholder="Введите гражданство по паспорту"
				label="Гражданство"
			/>

			<FindCityField
				:initial="cityProps.modelValue"
				:reset-form="cityProps.modelValue === ''"
				:country-code="currentCountry?.countryCode"
				@select="(value) => setValues({ city: value.city })"
			/>

			<BaseTextField
				v-model="address"
				v-bind="addressProps"
				placeholder="Введите адрес проживания"
				label="Адрес"
			/>
		</EditForm>
	</BaseFetchWrapper>
</template>
