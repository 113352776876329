import {
	saveIsAddPaymentEdit,
	saveProfile,
} from '../../infrastructure/store';
import { apiMethods } from '~/src/features/profile/infrastructure/api';
import type { EditPaymentForm } from '~/src/features/profile/types';
import { useGetProfile } from '~/src/features/profile';

export const useAddPartnerPayment = () => {
	const addPayment = apiMethods.useAddPartnerPayment();
	const getProfile = useGetProfile();

	return async (payload: EditPaymentForm) => {
		const result = await addPayment(payload);
		if (result.isRight()) {
			await getProfile();
		}
		else {
			throw result.value;
		}
	};
};

export const useChangePartnerPayment = () => {
	const changePayment = apiMethods.useChangePartnerPayment();
	const getProfile = useGetProfile();

	return async (id: string) => {
		const result = await changePayment(id);

		if (result.isRight()) {
			await getProfile();
		}
		else {
			throw result.value;
		}
	};
};

export const useChangePartnerDisablePayment = () => {
	const disablePayment = apiMethods.useChangePartnerDisablePayments();

	return async (payload: boolean) => {
		const result = await disablePayment(payload);

		if (result.isRight()) {
			saveProfile(result.value);
		}
		else {
			throw result.value;
		}
	};
};

export const setAddPaymentEdit = () => {
	saveIsAddPaymentEdit(true);
};

export const setAddPaymentNotEdit = () => {
	saveIsAddPaymentEdit(false);
};
