import type { EditEmailForm } from '../../core/types';
import { useApi } from '~/src/services/network';
import { toDomainTrader } from '~/src/features/profile/infrastructure/api/getTraderProfile';
import { toDomainPartner } from '~/src/features/profile/infrastructure/api/getPartnerProfile';

export const useChangeEmailTrader = () => {
	const api = useApi();

	return async (payload: EditEmailForm) =>
		(await api('/Trader/update/', {
			method: 'post',
			body: payload,
		})).mapRight(toDomainTrader);
};

export const useChangeEmailPartner = () => {
	const api = useApi();

	return async (payload: EditEmailForm) =>
		(await api('/Partner/update/', {
			method: 'post',
			body: payload,
		})).mapRight(toDomainPartner);
};
