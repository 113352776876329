import { apiMethods } from '../../infrastructure/api';
import { saveIsBlockAccountModalOpen } from '../../infrastructure/store';
import { useGetUser, useIsUserPartner, useIsUserTrader } from '~/src/features/user';

export const useBlockAccount = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const blockAccountTrader = apiMethods.useBlockAccountTrader();
	const blockAccountPartner = apiMethods.useBlockAccountPartner();

	const getUser = useGetUser();

	return async () => {
		let result = null;
		if (isTrader.value) {
			result = await blockAccountTrader();
		}
		else if (isPartner.value) {
			result = await blockAccountPartner();
		}

		if (result?.isRight()) {
			closeBlockAccountModal();
			await getUser();
		}
		else {
			throw result?.value;
		}
	};
};

export const closeBlockAccountModal = () => {
	saveIsBlockAccountModalOpen(false);
};

export const openBlockAccountModal = () => {
	saveIsBlockAccountModalOpen(true);
};
