<script setup lang="ts">
import {
	BaseCard, BaseCardTitle,
} from '@legacy-studio/library';
import Email from '../Email.vue';

const isLoading = ref(false);
const updateLoading = (value) => {
	isLoading.value = value;
};
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Электронная почта" />
		<Email
			@update-loading="updateLoading"
		/>
	</BaseCard>
</template>
