import { apiMethods } from '../../infrastructure/api';
import { saveIsLoginEdit, saveProfile } from '../../infrastructure/store';
import type { EditLoginForm } from '../../types';
import { useIsUserPartner, useIsUserTrader } from '@/src/features/user';
import { saveUserLogin } from '~/src/features/user/infrastructure/store';

export const useUpdateLogin = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const updateLoginTrader = apiMethods.useUpdateLoginTrader();
	const updateLoginPartner = apiMethods.useUpdateLoginPartner();

	return async (form: EditLoginForm) => {
		let result = null;
		if (isTrader.value) {
			result = await updateLoginTrader(form);
		}
		else if (isPartner.value) {
			result = await updateLoginPartner(form);
		}

		if (result?.isRight()) {
			saveProfile(result?.value);
			saveUserLogin(result?.value.login);
			setLoginNotEdit();
		}
		else {
			throw result?.value;
		}
	};
};

export const setLoginEdit = () => {
	saveIsLoginEdit(true);
};

export const setLoginNotEdit = () => {
	saveIsLoginEdit(false);
};
