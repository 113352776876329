<script setup lang="ts">
import { BaseButton, BaseFetchWrapper, BaseIcon, useAsyncState } from '@legacy-studio/library';
import EditForm from '../../Form.vue';
import {
	setPersonalityEdit,
	setPersonalityNotEdit, useAddTraderVerification,
} from '~/src/features/profile';
import { UiVerificationLabel } from '~/src/shared/ui';
import { useIsPersonalityEdit, useProfile } from '~/src/features/profile/infrastructure/store';
import type { TraderProfile } from '~/src/features/profile/infrastructure/api/getTraderProfile';

const user = useProfile() as ComputedRef<TraderProfile>;
const isPersonalityEdit = useIsPersonalityEdit();

const canBeEdited = computed(() => {
	return !user.value?.verification;
});

const status = computed(() => {
	if (!user.value?.verification || user.value?.verification?.status?.code === 'waiting') {
		return 'pending';
	}
	else if (user.value?.verification?.status?.code === 'verified') {
		return 'verified';
	}
	else if (user.value?.verification?.status?.code === 'rejected') {
		return 'unverified';
	}
	return 'unverified';
});

const verificationText = computed(() => {
	if (!user.value?.verification) {
		return 'Паспорт: Не предоставлен';
	}
	else if (user.value?.verification?.status?.code === 'verified') {
		return 'Паспорт предоставлен: Подтвержден';
	}
	else if (user.value?.verification?.status?.code === 'rejected') {
		return 'Паспорт предоставлен: Не подтвержден';
	}
	else if (user.value?.verification?.status?.code === 'waiting') {
		return 'Паспорт предоставлен: Ожидает подтверждения';
	}
	return 'Паспорт: Не предоставлен';
});

const fileInput = ref<HTMLInputElement | null>(null);
const inputError = ref<string | null>(null);
const fileUrl = ref<string | null>(null);

const handleFileChange = (event: Event) => {
	inputError.value = null;
	const input = event.target as HTMLInputElement;
	const file = input.files?.[0];

	if (!file) {
		return;
	}

	const maxSizeInMb = 1;
	const maxSizeInBytes = maxSizeInMb * 1024 * 1024;
	if (file.size > maxSizeInBytes) {
		inputError.value = 'Размер файла не должен превышать 1 МБ';
		fileUrl.value = null;
		input.value = '';
		return;
	}

	const reader = new FileReader();
	reader.onload = (e) => {
		fileUrl.value = e.target?.result as string;
	};

	reader.readAsDataURL(file);
};

const removeFile = () => {
	fileUrl.value = null;
	if (fileInput.value) {
		fileInput.value.value = '';
	}
};

const { execute, isLoading } = useAsyncState(useAddTraderVerification());

const onSubmit = async () => {
	if (!fileInput.value?.files?.length) {
		inputError.value = 'Обязательное поле';
		return;
	}
	const files = Array.from(fileInput.value.files);

	if (files.length) {
		await execute(files);
		fileInput.value.value = '';
		fileUrl.value = null;
		setPersonalityNotEdit();
	}
};

const cancelEdit = () => {
	fileUrl.value = null;
	fileInput.value.value = '';
	inputError.value = null;
	setPersonalityNotEdit();
};
</script>

<template>
	<BaseFetchWrapper
		class="relative flex flex-col gap-4"
		:pending="isLoading"
	>
		<div class="flex flex-col justify-between gap-4">
			<p class="text-base font-semibold text-white">
				Подтверждение личности
			</p>
			<div class="flex flex-col gap-4 md:flex-row items-center justify-between md:gap-[30px]">
				<UiVerificationLabel
					:status="status"
					:title="verificationText"
					subtitle="Ваша информация будет храниться в строгой конфиденциальности."
				/>
				<BaseButton
					v-if="!isPersonalityEdit && canBeEdited"
					color="alternative-dark"
					size="xs"
					button-text="Изменить"
					outline
					class="w-full md:w-auto"
					@click="setPersonalityEdit"
				/>
			</div>
		</div>
		<EditForm
			v-if="isPersonalityEdit"
			@submit="onSubmit"
			@cancel="cancelEdit"
		>
			<div class="flex flex-col gap-5">
				<div
					v-if="fileUrl"
					class="relative w-max"
				>
					<img
						class="h-20 w-20 object-cover rounded-lg"
						:src="fileUrl"
						alt="passport"
					>
					<div
						class="flex items-center justify-center bg-gray-400 h-6 w-6 rounded-full absolute right-[-12px] top-[-12px] cursor-pointer"
						@click="removeFile"
					>
						<BaseIcon
							name="base--fill--x"
							class="text-[14px] text-gray-800"
						/>
					</div>
				</div>

				<input
					ref="fileInput"
					type="file"
					class="sr-only"
					accept=".jpg, .jpeg, .png, .tiff, .pdf"
					@change="handleFileChange"
				>
				<div class="flex flex-col gap-2">
					<div
						class="h-20 flex justify-center items-center  bg-gray-700 border-2 rounded-lg border-dashed border-gray-600 cursor-pointer"
						:class="{
							'border-red-500': inputError,
						}"
						@click="fileInput?.click()"
					>
						<p class="text-center text-gray-400 text-sm font-normal">
							Загрузите фотографию или скан разворота <br>
							паспорта с фотографией и подписью.
						</p>
					</div>
					<span
						v-if="inputError"
						class="text-sm font-normal text-red-700"
					>
						{{ inputError }}
					</span>
				</div>

				<p class="text-xs font-normal text-gray-400">
					1. Все данные, включая серию и номер паспорта должны быть разборчиво видны; <br>
					2. Запрещено обрезать и редактировать изображение, в том числе скрывать часть данных; <br>
					3. Допустимые форматы: JPG, PNG, TIFF и PDF; размер до 1 Мб.
				</p>
			</div>
		</EditForm>
		<div
			v-if="user?.verification?.status?.code === 'rejected' && user?.verification?.rejectionReason"
			class="p-4 border border-red-400 rounded-md text-red-400 text-sm font-normal"
		>
			Ваша верификация отклонена. Для уточнения информации и решения вопроса, <br> пожалуйста, свяжитесь с нами через форму обратной связи или на support@bbroker.net
		</div>
	</BaseFetchWrapper>
</template>
