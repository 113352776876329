<script setup lang="ts">
import { BaseButton, BaseCard, BaseCardTitle, BaseCheckbox, BaseTextField, useAsyncState } from '@legacy-studio/library';
import { ref, computed, watch } from 'vue';
import {
	setTrafficEdit,
	setTrafficNotEdit,
	useChangePartnerTraffic,
} from '~/src/features/profile';
import EditForm from '~/src/features/profile/ui/Edit/Form.vue';
import { UiVerificationLabel } from '~/src/shared/ui';
import { useIsTrafficEdit, useProfile } from '~/src/features/profile/infrastructure/store';

const user = useProfile();
const isTrafficEdit = useIsTrafficEdit();

const items = ref<Array<{ value: string; label: string }>>([
	{ value: 'Свой сайт', label: 'Свой сайт' },
	{ value: 'Социальные сети', label: 'Социальные сети' },
	{ value: 'Арбитражный трафик', label: 'Арбитражный трафик' },
	{ value: 'Прямое взаимодействие с клиентами', label: 'Прямое взаимодействие с клиентами' },
]);

const selectedTraffic = ref(user.value?.traffic?.checkboxes);
const other = ref(false);
const otherTraffic = ref('');

const updateSelected = (value: any) => {
	selectedTraffic.value = value;
};

watch(user, () => {
	if (user.value?.traffic) {
		selectedTraffic.value = user.value?.traffic?.checkboxes;
		other.value = !!user.value?.traffic?.other;
		otherTraffic.value = user.value?.traffic?.other;
	}
});

const isConfirmed = computed(() => {
	if (!user.value) {
		return false;
	}
	return !!user.value?.traffic?.length;
});

const verificationText = computed(() => `Источники трафика: ${isConfirmed.value ? 'Добавлены' : 'Не добавлены'}`);

const { execute, isLoading } = useAsyncState(useChangePartnerTraffic());

const onSubmit = async () => {
	const payload = {
		checkboxes: selectedTraffic.value ?? [],
		other: other.value ? otherTraffic.value : '',
	};

	await execute(payload);
	setTrafficNotEdit();
};
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Трафик" />

		<div class="flex flex-col gap-4">
			<div class="flex flex-col gap-4 md:flex-row items-center justify-between md:gap-[30px]">
				<UiVerificationLabel
					:title="verificationText"
					:subtitle="`Помогите нам отследить эффективность различных каналов <br />
              привлечения трафика для улучшения промо-материала`"
				/>

				<BaseButton
					v-if="!isTrafficEdit"
					color="alternative-dark"
					size="xs"
					:button-text="`${user?.traffic ? 'Изменить':'Добавить'}`"
					outline
					class="w-full md:w-auto"
					@click="setTrafficEdit"
				/>
			</div>

			<EditForm
				v-if="isTrafficEdit"
				class="gap-8"
				@submit="onSubmit"
				@cancel="setTrafficNotEdit"
			>
				<span class="text-base font-semibold text-white">Виды источников</span>
				<div class="flex flex-col gap-4">
					<BaseCheckbox
						v-for="item in items"
						:key="item.value"
						:model-value="selectedTraffic || []"
						:value="item.value"
						:label="item.label"
						@update:model-value="updateSelected($event)"
					/>
					<BaseCheckbox
						v-model="other"
						label="Другое"
					/>
					<BaseTextField
						v-if="other"
						v-model="otherTraffic"
						placeholder="Введите ваш вид трафика"
					/>
				</div>
			</EditForm>
		</div>
	</BaseCard>
</template>
