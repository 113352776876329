<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseButton,
	BaseTextField,
	getTextFieldBinds, useAsyncState,
} from '@legacy-studio/library';
import {
	setEmailEdit,
	setEmailNotEdit, useChangeEmail,
} from '../../core/application';
import { editEmailSchema } from '../../core/validation';
import { useIsEmailEdit, useProfile } from '../../infrastructure/store';
import type { EditEmailForm } from '../../types';
import EditForm from './Form.vue';
import { UiVerificationLabel } from '@/src/shared/ui';
import EmailConfirmation from '~/src/features/profile/ui/Edit/EmailConfirmation.vue';

const user = useProfile();

const emit = defineEmits<{
	(event: 'updateLoading', value: any): void;
}>();

const isEmailEdit = useIsEmailEdit();

const status = computed(() => user.value?.emailVerified ? 'verified' : false);
const verificationEmailText = computed(() => {
	let verificationText = 'Не подтверждена';

	if (status.value) {
		verificationText = 'Подтверждена';
	}

	return `${user.value?.email}: ${verificationText} `;
});

const { defineField, handleSubmit, resetForm } = useForm<EditEmailForm>({
	validationSchema: editEmailSchema,
	initialValues: {
		email: '',
	},
});

const [email, emailProps] = defineField('email', getTextFieldBinds);

const { execute } = useAsyncState(useChangeEmail());

const onSubmitEmail = handleSubmit(async (values) => {
	emit('updateLoading', true);
	const data = await execute(values);
	if (data?.result) {
		step.value = 1;
	}
	emit('updateLoading', false);
});

const step = ref(0);

const onSuccessConfirm = () => {
	step.value = 0;
	resetForm();
};
</script>

<template>
	<template v-if="step === 0">
		<div class="flex flex-col gap-4">
			<div class="flex flex-col gap-4 md:flex-row items-center justify-between md:gap-[30px]">
				<UiVerificationLabel
					:status="status"
					:title="verificationEmailText"
					subtitle="Вы можете сменить электронную почту, к которой привязан ваш счет."
				/>

				<BaseButton
					v-if="!isEmailEdit"
					color="alternative-dark"
					size="xs"
					button-text="Изменить"
					outline
					class="w-full md:w-auto"
					@click="setEmailEdit"
				/>
			</div>

			<EditForm
				v-if="isEmailEdit"
				class="mt-4"
				@submit="onSubmitEmail"
				@cancel="setEmailNotEdit"
			>
				<BaseTextField
					v-model="email"
					v-bind="emailProps"
					size="regular"
					dark
					placeholder="Введите новый e-mail"
					label="Новый e-mail"
				/>
			</EditForm>
		</div>
	</template>
	<template v-if="step === 1">
		<EmailConfirmation
			:email="email"
			@on-success="onSuccessConfirm"
		/>
	</template>
</template>
