import { apiMethods } from '../../infrastructure/api';
import { saveIsChangePasswordEdit } from '../../infrastructure/store';
import type { ChangePasswordForm } from '../../types';
import { useIsUserPartner, useIsUserTrader } from '@/src/features/user';

export const useChangePassword = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const changePasswordTrader = apiMethods.useChangePasswordTrader();
	const changePasswordPartner = apiMethods.useChangePasswordPartner();

	return async (payload: ChangePasswordForm) => {
		let result = null;
		if (isTrader.value) {
			result = await changePasswordTrader(payload);
		}
		else if (isPartner.value) {
			result = await changePasswordPartner(payload);
		}

		if (result?.isRight()) {
			closeChangePasswordModal();
		}
		else {
			throw result?.value;
		}
	};
};

export const openChangePasswordModal = () => {
	saveIsChangePasswordEdit(true);
};

export const closeChangePasswordModal = () => {
	saveIsChangePasswordEdit(false);
};
