import {
	readIsSidebarOpen,
	saveIsSidebarOpen,
} from '../../infrastructure/store';

export const toggleSidebar = () => {
	saveIsSidebarOpen(!readIsSidebarOpen());
};

export const closeSidebar = () => {
	saveIsSidebarOpen(false);
};

export const openSidebar = () => {
	saveIsSidebarOpen(true);
};
