<script setup lang="ts">
import {
	BaseIcon,
	BaseTextField,
	getTextFieldBinds, useAsyncState,
} from '@legacy-studio/library';
import { useForm } from 'vee-validate';
import { useIsAddPaymentEdit } from '~/src/features/profile/infrastructure/store';
import {
	setAddPaymentNotEdit,
	useAddPartnerPayment,
} from '~/src/features/profile/core/application/updatePartnerPayment';
import EditForm from '~/src/features/profile/ui/Edit/Form.vue';
import { useGetCategories } from '~/src/features/payment-method/core/application';
import { usePaymentMethodsCategories } from '~/src/features/payment-method/infrastructure/store';
import type { EditPaymentForm } from '~/src/features/profile/types';
import { editPaymentSchema } from '~/src/features/profile/core/validation/editPayment';
import PaymentMethods from '~/src/features/profile/ui/Edit/Partner/PaymentMethods.vue';

const emit = defineEmits<{
	(event: 'setLoading' | 'setNotLoading'): void;
}>();

const isAddPaymentEdit = useIsAddPaymentEdit();

await useAsyncData(useGetCategories());
const categories = usePaymentMethodsCategories();

const filterCategory = ref('');
const filteredCategories = computed(() => {
	if (!filterCategory.value) {
		return categories.value;
	}
	else {
		return (
			categories.value?.filter(
				category => category.code === filterCategory.value,
			) || []
		);
	}
});

const { defineField, handleSubmit, setFieldValue } = useForm<EditPaymentForm>({
	validationSchema: editPaymentSchema,
	initialValues: {
		account: '',
		paysystem: '',
	},
});

const [account, accountProps] = defineField('account', getTextFieldBinds);
const [paysystemModel] = defineField('paysystem', getTextFieldBinds);

const choiceOpen = ref(false);
const currentPaymentMethod = ref(null);
const selectPaymentMethod = (section, paymentMethod) => {
	currentPaymentMethod.value = {
		section,
		paymentMethod,
	};
	choiceOpen.value = false;
};

const setFilterCategory = (category) => {
	filterCategory.value = category;
};

watch(
	currentPaymentMethod,
	(value) => {
		paysystemModel.value = value?.paymentMethod.id;
		setFieldValue('account', '', false);
	},
	{
		immediate: true,
	},
);

const openChoice = () => {
	choiceOpen.value = true;
};

const { execute: executeAdd } = useAsyncState(useAddPartnerPayment());

const onSubmit = handleSubmit(async (values) => {
	emit('setLoading');
	await executeAdd(values);
	emit('setNotLoading');

	setAddPaymentNotEdit();
	currentPaymentMethod.value = null;
});
</script>

<template>
	<EditForm
		v-if="isAddPaymentEdit"
		class="gap-8"
		@submit="onSubmit"
		@cancel="setAddPaymentNotEdit"
	>
		<div
			class="flex flex-col gap-2"
		>
			<span class="text-sm font-medium text-white">Способ вывода</span>

			<div class="flex flex-col gap-4">
				<div
					class="relative py-3 px-4 pr-[42px] bg-gray-700 border border-gray-600 rounded-lg cursor-pointer"
					@click="openChoice"
				>
					<span
						v-if="!currentPaymentMethod"
						class="leading-tight text-sm font-normal text-gray-400 flex"
					>
						Выберите способ вывода
					</span>
					<div
						v-else-if="currentPaymentMethod"
						class="leading-tight text-sm flex justify-between"
					>
						<span class="font-normal text-white">
							{{ currentPaymentMethod?.section?.name }}
						</span>
						<span
							class="font-medium text-gray-400"
						>
							{{ currentPaymentMethod?.paymentMethod?.name }}
						</span>
					</div>

					<BaseIcon
						name="base--fill--chevron-down"
						class="absolute right-4 top-3 text-[16px] text-white"
					/>
				</div>
				<div
					v-if="currentPaymentMethod"
					class="flex flex-col gap-2"
				>
					<BaseTextField
						v-if="currentPaymentMethod?.section.code === 'crypto' || currentPaymentMethod?.section.code === 'electroWallet'"
						v-model="account"
						v-bind="accountProps"
						:label="currentPaymentMethod?.paymentMethod.validation.name"
						size="regular"
						:placeholder="currentPaymentMethod?.paymentMethod.validation.placeholder"
					/>

					<BaseTextField
						v-else-if="currentPaymentMethod?.section.code === 'bankCard'"
						v-model="account"
						v-bind="accountProps"
						:label="currentPaymentMethod.paymentMethod.validation.name"
						size="regular"
						:placeholder="currentPaymentMethod?.paymentMethod.validation.placeholder"
						maska="####-####-####-####"
					/>

					<span
						v-if="currentPaymentMethod?.paymentMethod?.validation?.message "
						class="leading-tight text-sm font-normal text-gray-400"
					>
						{{ currentPaymentMethod?.paymentMethod?.validation?.message }}
					</span>
				</div>
			</div>

			<PaymentMethods
				v-if="choiceOpen"
				:filter-category="filterCategory"
				:filtered-categories="filteredCategories"
				:categories="categories"
				@select-payment-method="selectPaymentMethod"
				@set-filter-category="setFilterCategory"
			/>
		</div>
	</EditForm>
</template>
