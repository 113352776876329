import { useApi } from '@/src/services/network';
import { toDomainTrader } from '~/src/features/profile/infrastructure/api/getTraderProfile';
import { toDomainPartner } from '~/src/features/profile/infrastructure/api/getPartnerProfile';

export const useChangeLocaleTrader = () => {
	const api = useApi();

	return async (language: string) =>
		(await api('/Trader/update/', {
			method: 'post',
			body: {
				language,
			},
		})).mapRight(toDomainTrader);
};

export const useChangeLocalePartner = () => {
	const api = useApi();

	return async (language: string) =>
		(await api('/Partner/update/', {
			method: 'post',
			body: {
				language,
			},
		})).mapRight(toDomainPartner);
};
