import type { EditLoginForm } from '../../types';
import { useApi } from '~/src/services/network';
import { toDomainPartner } from '~/src/features/profile/infrastructure/api/getPartnerProfile';
import { toDomainTrader } from '~/src/features/profile/infrastructure/api/getTraderProfile';

export const useUpdateLoginTrader = () => {
	const api = useApi();

	return async (payload: EditLoginForm) =>
		(await api('/Trader/update/', {
			method: 'post',
			body: payload,
		})).mapRight(toDomainTrader);
};

export const useUpdateLoginPartner = () => {
	const api = useApi();

	return async (payload: EditLoginForm) =>
		(await api('/Partner/update/', {
			method: 'post',
			body: payload,
		})).mapRight(toDomainPartner);
};
