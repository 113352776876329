import { saveIsTrafficEdit, saveProfile } from '../../infrastructure/store';
import { apiMethods } from '~/src/features/profile/infrastructure/api';
import type { EditTrafficPartnerPayload } from '~/src/features/profile/types';

export const useChangePartnerTraffic = () => {
	const changeTraffic = apiMethods.useChangePartnerTraffic;

	return async (form: EditTrafficPartnerPayload) => {
		const result = await changeTraffic(form);
		if (result.isRight()) {
			saveProfile(result.value);
		}
		else {
			throw result.value;
		}
	};
};

export const setTrafficEdit = () => {
	saveIsTrafficEdit(true);
};

export const setTrafficNotEdit = () => {
	saveIsTrafficEdit(false);
};
