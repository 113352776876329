<script setup lang="ts">
import { UiDarkBBButton } from '~/src/shared/ui';

interface Props {
	filteredCategories: any;
	filterCategory: string;
	categories: any;
}
const props = withDefaults(defineProps<Props>(), {
	filteredCategories: [],
	filterCategory: '',
	categories: [],
});

const emit = defineEmits<{
	(event: 'setFilterCategory', category: string): void;
	(event: 'selectPaymentMethod', section, item): void;
}>();
</script>

<template>
	<div
		class="p-4 bg-gray-700 border border-gray-600 rounded-lg text-white flex flex-col gap-3"
	>
		<span class="text-sm font-semibold">
			Выберите удобный способ вывода
		</span>
		<div class="flex gap-4 overflow-auto whitespace-nowrap scrollbar-hidden">
			<UiDarkBBButton
				:outline="!!filterCategory"
				size="sm"
				button-text="Все"
				class="!rounded-full focus:!ring-0"
				@click="emit('setFilterCategory', '')"
			/>

			<UiDarkBBButton
				v-for="button in categories"
				:key="button.code"
				size="sm"
				:outline="filterCategory !== button.code"
				:button-text="button.name"
				class="!rounded-full focus:!ring-0"
				@click="emit('setFilterCategory', button.code)"
			/>
		</div>
		<div class="flex flex-col gap-6">
			<div
				v-for="section in filteredCategories"
				:key="section.id"
				class="flex flex-col gap-6"
			>
				<span class="text-base font-semibold">
					{{ section.name }}
				</span>

				<div class="flex flex-col gap-3">
					<button
						v-for="item in section.items"
						:key="item.id"
						type="button"
						class="bg-gray-600 rounded-lg p-3 flex items-center gap-3"
						@click="emit('selectPaymentMethod', section, item)"
					>
						<img
							v-if="item.image"
							:src="item.image"
							:alt="item.image"
							class="w-[24px] h-[24px] object-contain"
						>
						<div
							v-else
							class="w-[24px] h-[24px]"
						/>

						<span class="text-base font-bold">
							{{ item.name }}
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
