import { useApi } from '@/src/services/network';
import type {
	Response, USER_ACCOUNT_TYPE, UserAccountType, type UserColorCode, UserGroupId, type UserId,
} from '~/src/shared/lib';
import { getUserGroupId, type UserGroupsDTO } from '~/src/features/user/infrastructure/api/getUser';

interface PaysystemDTO {
	id: string;
	name: string;
}

interface PaymentTypeDTO {
	id: string;
	name: string;
}

interface PaymentInfoItemDTO {
	id: string;
	account: string;
	is_selected: '0' | '1';
	paysystem: PaysystemDTO;
	type: PaymentTypeDTO;
}

export type PaymentInfoDTO = Response<{
	items: Array<PaymentInfoItemDTO>;
	count: number;
}>;

export type PartnerProfileDTO = Response<{
	id: UserId;
	name: string;
	last_name: string;
	login: string;
	allow_news_dist: '0' | '1';
	allow_statistics_dist: '0' | '1';
	color: UserColorCode;
	country: {
		id: string;
		code: string;
		name: {
			ru: string;
			en: string;
		};
		phone_pref: string;
	} | null;
	date_register: DateString;
	disable_payments: '0' | '1';
	distribution_language: string;
	email: Email;
	group: UserGroupsDTO;
	language: string;
	payment_info: {
		items: Array<PaymentInfoItemDTO>;
		count: number;
	};
	phone_number: string;
	photo: Url | null;
	referal_code: string;
	skype: string;
	tariff: {
		id: string;
		name: string;
		code: UserAccountType;
	};
	telegram: string;
	telegram_accounts: Array<string>;
	traffic: string | null;
	email_verified: '0' | '1';
	jwt: string | null;
}>;

export interface PartnerProfileTraffic {
	other: string;
	checkboxes: Array<string>;
}

interface PaymentInfoItem {
	id: string;
	account: string;
	isSelected: boolean;
	paymentSystem: {
		id: string;
		name: string;
	};
	paymentType: {
		id: string;
		name: string;
	};
}

export interface PaymentInfo {
	items: Array<PaymentInfoItem>;
	count: number;
}

export interface PartnerProfile {
	id: UserId;
	login: string;
	firstName: string;
	lastName: string;
	emailVerified: boolean;
	allowNewsDist: boolean;
	allowStatisticsDist: boolean;
	colorCode: UserColorCode;
	country: {
		id: string;
		code: string;
		name: {
			ru: string;
			en: string;
		};
		phonePref: string;
	} | null;
	dateRegister: DateString;
	disablePayments: boolean;
	distributionLanguage: string;
	email: Email;
	groupId: UserGroupId;
	accountType: UserAccountType;
	language: string;
	paymentInfo: PaymentInfo;
	phone: string;
	photo: Url | null;
	referralCode: string;
	skype: string;
	telegram: string;
	telegramAccounts: Array<string>;
	traffic: PartnerProfileTraffic;
	jwt: string | null;
}

export const toDomainPartner = (user: PartnerProfileDTO): PartnerProfile => {
	const traffic: PartnerProfileTraffic = {
		checkboxes: [],
		other: '',
	};
	if (user.result.traffic) {
		const [checkboxesPart, other] = user.result.traffic.split('>other<');
		traffic.checkboxes = checkboxesPart.split('><');
		traffic.other = other;
	}

	const partner = {
		id: user.result.id,
		login: user.result.login,
		email: user.result.email,
		firstName: user.result.name,
		lastName: user.result.last_name,
		photo: user.result.photo,
		phone: user.result.phone_number,
		colorCode: user.result.color || '1',
		accountType: user.result.tariff?.code ?? USER_ACCOUNT_TYPE.free,
		groupId: getUserGroupId(user.result.group),
		language: user.result.language,
		referralCode: user.result.referal_code,
		country: user.result.country,
		emailVerified: Boolean(+user.result.email_verified),
		allowNewsDist: Boolean(+user.result.allow_news_dist),
		allowStatisticsDist: Boolean(+user.result.allow_statistics_dist),
		dateRegister: user.result.date_register,
		disablePayments: Boolean(+user.result.disable_payments),
		distributionLanguage: user.result.distribution_language,
		paymentInfo: {
			items: user.result.payment_info.items.map((item: PaymentInfoItemDTO) => ({
				id: item.id,
				account: item.account,
				isSelected: Boolean(+item.is_selected),
				paymentSystem: {
					id: item.paysystem.id,
					name: item.paysystem.name,
				},
				paymentType: {
					id: item.type.id,
					name: item.type.name,
				},
			})),
			count: user.result.payment_info.count,
		},
		skype: user.result.skype,
		telegram: user.result.telegram,
		telegramAccounts: user.result.telegram_accounts,
		traffic: traffic,
		jwt: user.result.jwt,
	};
	return partner;
};

export const useGetPartnerProfile = () => {
	const api = useApi();

	return async () => (await api<PartnerProfileDTO>('/api/Partner/getProfile/')).mapRight(toDomainPartner);
};
