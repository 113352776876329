import { apiMethods } from '../../infrastructure/api';
import { useIsUserPartner, useIsUserTrader } from '@/src/features/user';
import { saveUserPhoto } from '~/src/features/user/infrastructure/store';
import { saveProfile } from '~/src/features/profile/infrastructure/store';

export const useUploadAvatar = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const uploadAvatarTrader = apiMethods.useUploadAvatarTrader();
	const uploadAvatarPartner = apiMethods.useUploadAvatarPartner();

	return async (avatar: File) => {
		let result = null;
		if (isTrader.value) {
			result = await uploadAvatarTrader(avatar);
		}
		else if (isPartner.value) {
			result = await uploadAvatarPartner(avatar);
		}

		if (result?.isRight()) {
			saveProfile(result?.value);
			saveUserPhoto(result?.value.photo);
		}
		else {
			throw result?.value;
		}
	};
};
