<script setup lang="ts">
import {
	BaseTextField,
	BaseSelect,
	useAsyncState,
	BaseButton,
	getTextFieldBinds,
	getSelectBinds,
	BaseCard,
	BaseCardTitle,
} from '@legacy-studio/library';
import { useForm } from 'vee-validate';
import { editPersonalPartnerSchema } from '../../../core/validation';
import type { EditPersonalPartnerForm } from '../../../types';

import { useIsPersonalEdit } from '../../../infrastructure/store';
import EditForm from '../Form.vue';
import {
	setPersonalEdit,
	setPersonalNotEdit,
	useUpdatePartnerPersonal,
} from '~/src/features/profile';
import {
	useSharedDataCountries,
	useSharedDataGetCountries,
} from '~/src/features/shared-data';
import {
	UiVerificationLabel,
} from '~/src/shared/ui';
import { useProfile } from '~/src/features/profile/infrastructure/store';
import FlagLogo from '~/src/shared/ui/FlagLogo.vue';

const user = useProfile();
const isPersonalEdit = useIsPersonalEdit();

const isConfirmed = computed(() => {
	if (!user.value) {
		return false;
	}

	return (
		!!user.value?.firstName
		&& !!user.value?.lastName
		&& !!user.value?.country?.code
	);
});

const status = computed(() => isConfirmed.value ? 'verified' : false);
const verificationText = computed(() => `Данные: ${isConfirmed.value ? 'Предоставлены' : 'Не предоставлены'}`);

// const tgBot = ref('');
// const telegramAccounts = ref(user.value.telegramAccounts);
//
// watch(user, (newUser) => {
// 	telegramAccounts.value = newUser.value.telegramAccounts;
// });

// const deleteTGAccount = (index) => {
// 	telegramAccounts.value.splice(index, 1);
// };
//
// const addTGAccount = () => {
// 	telegramAccounts.value.push(tgBot.value);
// 	tgBot.value = '';
// };

await useSharedDataGetCountries()();
const countries = useSharedDataCountries();
const countriesRu = computed(() => countries.value.map((item) => {
	return {
		countryCode: item.code,
		name: item.name.ru,
		code: item.id,
	};
}));
const currentCountry = computed(() =>
	country.value
		? countriesRu.value.find(item => item.code === country.value)
		: null,
);

const { defineField, handleSubmit } = useForm<EditPersonalPartnerForm>({
	validationSchema: editPersonalPartnerSchema,
	initialValues: user.value
		? {
				firstName: user.value.firstName,
				lastName: user.value.lastName,
				phone: user.value.phone ?? '',
				country: (user.value.country as any)?.id, // TOOD: ?
				skype: user.value.skype ?? '',
				telegram: user.value.telegram ?? '',
			}
		: {},
});

const [firstName, firstNameProps] = defineField('firstName', getTextFieldBinds);
const [lastName, lastNameProps] = defineField('lastName', getTextFieldBinds);
const [phone, phoneProps] = defineField('phone', getTextFieldBinds);
const [country, countryProps] = defineField('country', getSelectBinds);
const [skype, skypeProps] = defineField('skype', getSelectBinds);
const [telegram, telegramProps] = defineField('telegram', getSelectBinds);

const { execute, isLoading } = useAsyncState(useUpdatePartnerPersonal());

const onSubmit = handleSubmit(async (values) => {
	// values.telegram_account = [...telegramAccounts.value];
	await execute(values);
});
</script>

<template>
	<BaseCard
		:loading="isLoading"
	>
		<BaseCardTitle title="Персональные данные" />

		<div class="flex flex-col gap-4">
			<div class="flex flex-col gap-4 md:flex-row items-center justify-between md:gap-[30px]">
				<UiVerificationLabel
					:status="status"
					:title="verificationText"
					subtitle="Ваша информация будет храниться в строгой конфиденциальности."
				/>

				<BaseButton
					v-if="!isPersonalEdit"
					color="alternative-dark"
					size="xs"
					button-text="Изменить"
					outline
					class="w-full md:w-auto"
					@click="setPersonalEdit"
				/>
			</div>
			<EditForm
				v-if="isPersonalEdit"
				@submit="onSubmit"
				@cancel="setPersonalNotEdit"
			>
				<BaseTextField
					v-model="firstName"
					v-bind="firstNameProps"
					placeholder="Введите имя по паспорту"
					label="Имя"
				/>

				<BaseTextField
					v-model="lastName"
					v-bind="lastNameProps"
					placeholder="Введите фамилию по паспорту"
					label="Фамилия"
				/>

				<BaseSelect
					v-model="country"
					v-bind="countryProps"
					placeholder="Выберите страну"
					label="Страна проживания"
					:items="countriesRu"
				>
					<template
						v-if="country"
						#default
					>
						<div class="flex gap-2 items-center">
							<FlagLogo
								:size="'xs'"
								:code="currentCountry?.countryCode"
							/>
							{{ currentCountry?.name }}
						</div>
					</template>
					<template #item="{ item }">
						<div class="flex gap-2 items-center">
							<FlagLogo
								:size="'xs'"
								:code="item?.countryCode"
							/>
							{{ item?.name }}
						</div>
					</template>
				</BaseSelect>

				<span class="text-base font-semibold text-white">
					Контакты для связи
				</span>

				<BaseTextField
					v-model="phone"
					v-bind="phoneProps"
					placeholder="Телефон"
					label="Номер телефона"
				/>

				<BaseTextField
					v-model="skype"
					v-bind="skypeProps"
					placeholder="Введите ваш логин Skype"
					label="Skype"
				/>

				<BaseTextField
					v-model="telegram"
					v-bind="telegramProps"
					placeholder="Введите ваш логин в Telegram"
					label="Telegram"
				/>

				<!--				<div class="flex flex-col gap-4"> -->
				<!--					<div class="flex flex-col gap-2"> -->
				<!--						<span class="text-sm font-medium text-white"> -->
				<!--							Telegram бот -->
				<!--						</span> -->
				<!--						<div class="flex gap-2.5"> -->
				<!--							<div class="flex flex-col gap-2"> -->
				<!--								<BaseTextField -->
				<!--									v-model="tgBot" -->
				<!--									placeholder="Введите ваш логин в Telegram" -->
				<!--								/> -->
				<!--								<span class="text-sm font-normal text-gray-400"> -->
				<!--									Добавьте Telegram бота, чтобы получить уникальный код и подключить статистику -->
				<!--									<BaseLink -->
				<!--										to="" -->
				<!--										class="text-primary-200" -->
				<!--									> -->
				<!--										@BBaffBot -->
				<!--									</BaseLink> -->
				<!--								</span> -->
				<!--							</div> -->
				<!--							<UiDarkBBButton -->
				<!--								button-text="Применить" -->
				<!--								type="button" -->
				<!--								@click="addTGAccount" -->
				<!--							/> -->
				<!--						</div> -->
				<!--					</div> -->

				<!--					<div -->
				<!--						v-if="telegramAccounts.length" -->
				<!--						class="flex flex-col gap-3" -->
				<!--					> -->
				<!--						<span class="text-sm font-medium text-white"> -->
				<!--							Подключенные аккаунты к Telegram боту -->
				<!--						</span> -->
				<!--						<div class="bg-gray-800 border border-gray-700 p-3 flex gap-2.5 rounded-md"> -->
				<!--							<BaseBadge -->
				<!--								v-for="(badge, index) in telegramAccounts" -->
				<!--								:text="badge" -->
				<!--								color="gray" -->
				<!--								:closable="true" -->
				<!--								@close="deleteTGAccount(index)" -->
				<!--							/> -->
				<!--						</div> -->
				<!--					</div> -->
				<!--				</div> -->
			</EditForm>
		</div>
	</BaseCard>
</template>
