import { useApi } from '@/src/services/network';
import { toDomainPartner } from '@/src/features/profile/infrastructure/api/getPartnerProfile';

const fromDomainMailing = (payload: boolean): any => {
	return {
		allow_news_dist: payload ? '1' : '0',
	};
};

export const useChangePartnerMailing = () => {
	const api = useApi();

	return async (payload: boolean) =>
		(await api('/Partner/update/', {
			method: 'POST',
			body: fromDomainMailing(payload),
		})).mapRight(toDomainPartner);
};
