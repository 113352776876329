import { saveProfile, useProfile } from '../../infrastructure/store';
import { apiMethods } from '~/src/features/profile/infrastructure/api';

export const useChangePartnerMailing = () => {
	const changeMailing = apiMethods.useChangePartnerMailing();

	const user = useProfile();
	return async (isAllowedNewsDist: boolean) => {
		const result = await changeMailing(isAllowedNewsDist);
		if (result.isRight()) {
			saveProfile(result.value);
		}
		else {
			throw result.value;
		}
	};
};
