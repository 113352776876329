import { useApi } from '@/src/services/network';
import type { EditTrafficPartnerPayload } from '@/src/features/profile/types';
import { toDomainPartner } from '@/src/features/profile/infrastructure/api/getPartnerProfile';
import type { PartnerProfile } from '~/src/features/profile/infrastructure/api/getPartnerProfile';

const fromDomainTraffic = (payload: EditTrafficPartnerPayload): any => {
	const traffic = payload.checkboxes.join('><') + '>other<' + payload.other;

	return {
		traffic,
	};
};

export const useChangePartnerTraffic: PartnerProfile = async (payload: EditTrafficPartnerPayload) => {
	const result = await useApi()('/Partner/update/', {
		method: 'POST',
		body: fromDomainTraffic(payload),
	});
	return result.mapRight(toDomainPartner);
};
