import { useApi } from '@/src/services/network';
import type {
	Response,
} from '~/src/shared/lib';

export interface FileDTO {
	id: number;
	path: string;
}

export interface FilesDTO {
	[key: number]: FileDTO;
}

export type VerificationDTO = Response<{
	id: string;
	user: string;
	status: {
		id: string;
		name: string;
		code: string;
	};
	date_create: string;
	date_confirm: string;
	rejection_reason: string;
	files: FilesDTO;
}>;

export interface TraderVerification {
	id: string;
	user: string;
	status: {
		id: string;
		name: string;
		code: string;
	};
	dateCreate: string;
	dateConfirm: string;
	rejectionReason: string;
	files: string[];
}

export const toDomainVerification = (verification: VerificationDTO): TraderVerification => {
	return {
		id: verification.result.id,
		user: verification.result.id,
		status: {
			id: verification.result.status.id,
			name: verification.result.status.name,
			code: verification.result.status.code,
		},
		dateCreate: verification.result.date_create,
		dateConfirm: verification.result.date_confirm,
		rejectionReason: verification.result.rejection_reason,
		files: Object.values(verification.result.files as FilesDTO).map((file: FileDTO) => file.path),
	};
};

export const useAddTraderVerification = () => {
	const api = useApi();

	return async (files: File[]) => {
		const formData = new FormData();
		files.forEach((file, index) => {
			formData.append(`files[${index}]`, file);
		});

		return (await api('/Trader/addVerification/', {
			method: 'post',
			body: formData,
		})).mapRight(toDomainVerification);
	};
};
