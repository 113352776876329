import { apiMethods } from '../../infrastructure/api';
import { saveProfile } from '../../infrastructure/store';
import { useIsUserPartner, useIsUserTrader } from '~/src/features/user';

export const useGetProfile = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const getTraderProfile = apiMethods.useGetTraderProfile();
	const getPartnerProfile = apiMethods.useGetPartnerProfile();

	return async () => {
		let result = null;

		if (isTrader.value) {
			result = await getTraderProfile();
		}
		else if (isPartner.value) {
			result = await getPartnerProfile();
		}

		if (result?.isRight()) {
			saveProfile(result?.value);
		}
		else {
			throw result?.value;
		}
	};
};
