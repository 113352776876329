<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseCodeInputList,
	BaseFetchWrapper,
	getTextFieldBinds,
	useAsyncState,
} from '@legacy-studio/library';
import { UiDarkBBButton, UiError } from '~/src/shared/ui';
import { createCodeConfirmationSchema } from '~/src/features/auth/core/validation';
import type { CodeConfirmationForm } from '~/src/features/auth/types';
import { useConfirmEmail } from '~/src/features/profile';

const props = defineProps<{
	email: string;
}>();

const emit = defineEmits<{
	(event: 'onSuccess'): void;
}>();

const { execute, error, isLoading } = useAsyncState(useConfirmEmail());

const { defineField, handleSubmit } = useForm<CodeConfirmationForm>({
	validationSchema: createCodeConfirmationSchema,
	initialValues: {
		email: props.email,
		code: '',
	},
});

const [code, codeProps] = defineField('code', getTextFieldBinds);
const [emailModel] = defineField('email', getTextFieldBinds);

watch(
	() => props.email,
	(email) => {
		emailModel.value = email;
	},
	{
		immediate: true,
	},
);

const onSubmit = handleSubmit(async (values) => {
	const data = await execute(values);
	if (data?.result) {
		emit('onSuccess');
	}
});
</script>

<template>
	<BaseFetchWrapper
		:pending="isLoading"
	>
		<form
			class="flex w-full flex-col gap-4 md:gap-5"
			@submit.prevent="onSubmit"
		>
			<p class="text-sm font-normal text-gray-500">
				Мы отправили шестизначный код на
				<span class="font-normal text-white"> {{ email }}.</span>
				Введите код ниже, чтобы подтвердить свой адрес электронной почты.
			</p>

			<BaseCodeInputList
				v-model="code"
				v-bind="codeProps"
				class="max-w-[516px]"
			/>
			<UiDarkBBButton
				button-text="Подтвердить почту"
				type="submit"
			/>
		</form>
		<UiError
			:error="error"
			class="mt-4"
		/>
	</BaseFetchWrapper>
</template>
