<script setup lang="ts">
import { BaseButton, BaseCard, useAsyncState } from '@legacy-studio/library';
import { useDeleteAvatar, useUploadAvatar } from '../../core/application';
import ProfileIdBadge from './ProfileIdBadge.vue';
import { getBgColor, useIsUserPartner, useIsUserTrader, useUserRequired } from '@/src/features/user';
import { TariffsBadge } from '~/src/features/tariffs';
import ProfileRefBadge from '~/src/features/profile/ui/Edit/ProfileRefBadge.vue';
import ProfileRegistrationBadge from '~/src/features/profile/ui/Edit/ProfileRegistrationBadge.vue';

const user = useUserRequired();

const isTrader = useIsUserTrader();
const isPartner = useIsUserPartner();

const deleteAvatarCommand = useAsyncState(useDeleteAvatar());
const uploadAvatarCommand = useAsyncState(useUploadAvatar());

const pending = computed(
	() =>
		deleteAvatarCommand.isLoading.value || uploadAvatarCommand.isLoading.value,
);

const onUploadAvatar = async (e: Event) => {
	const input = e.target as HTMLInputElement;
	const file = input.files?.[0] as File;

	if (file) {
		await uploadAvatarCommand.execute(file);
		input.value = '';
	}
};

const fileInput = ref<HTMLInputElement | null>(null);

const onDeleteAvatar = deleteAvatarCommand.execute;
</script>

<template>
	<BaseCard :loading="pending">
		<div class="grid gap-4 grid-cols-[auto_1fr] md:grid-rows-[auto_auto]">
			<div class="h-[112px] w-[112px] overflow-hidden rounded-lg col-start-1 row-start-1 md:row-span-2">
				<img
					v-if="user.photo"
					class="h-full w-full object-cover"
					:src="user.photo"
					alt="avatar"
				>
				<div
					v-else
					class="flex h-full w-full items-center justify-center text-3xl font-medium leading-none text-white"
					:class="getBgColor(user)"
				>
					{{ user.firstName && user.lastName ? user.firstName?.[0] + user.lastName?.[0] : user.login?.[0] }}
				</div>
			</div>

			<div class="flex flex-col gap-2 col-span-2 col-start-1 row-start-2 md:col-start-2 md:row-start-1">
				<div class="flex flex-col gap-3 md:flex-row md:items-center">
					<span class="text-2xl font-bold text-white">
						{{ user.login }}
					</span>

					<div class="flex gap-2 flex-wrap">
						<ProfileIdBadge
							v-if="isTrader"
							:id="user.id"
						/>

						<TariffsBadge
							v-if="isTrader"
							:type="user.accountType"
						/>

						<ProfileRegistrationBadge
							v-if="isPartner"
							:date="user.registrationDate"
						/>

						<ProfileRefBadge
							v-if="isPartner"
							:refcode="user.id"
						/>
					</div>
				</div>

				<span class="text-base text-gray-400">
					{{ user.email }}
				</span>
			</div>

			<div class="flex gap-4 flex-col md:flex-row items-start md:justify-start col-start-2 row-start-1 md:col-start-2 md:row-start-2 ">
				<label>
					<input
						ref="fileInput"
						type="file"
						class="sr-only"
						@input="onUploadAvatar"
					>

					<BaseButton
						color="alternative-dark"
						size="xs"
						:button-text="user.photo ? 'Изменить фото' : 'Загрузить фото'"
						outline
						class="w-fit"
						@click="fileInput?.click()"
					/>
				</label>

				<BaseButton
					v-if="user.photo"
					color="alternative-dark"
					size="xs"
					button-text="Удалить фото"
					outline
					class="w-fit"
					@click="onDeleteAvatar"
				/>
			</div>
		</div>
	</BaseCard>
</template>
