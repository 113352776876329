import { apiMethods } from '../../infrastructure/api';

import type { EditEmailForm } from '../../types';
import { saveIsEmailEdit } from '../../infrastructure/store';
import { apiMethods as apiMethodsAuth } from '~/src/features/auth/infrastructure/api';
import { useGetUser, useIsUserPartner, useIsUserTrader } from '@/src/features/user';
import { useGetProfile } from '~/src/features/profile';
import { TokenService } from '~/src/features/auth';

export const useChangeEmail = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const changeEmailTrader = apiMethods.useChangeEmailTrader();
	const changeEmailPartner = apiMethods.useChangeEmailPartner();

	return async (payload: EditEmailForm) => {
		let result = null;
		if (isTrader.value) {
			result = await changeEmailTrader(payload);
		}
		else if (isPartner.value) {
			result = await changeEmailPartner(payload);
		}

		if (result?.isRight()) {
			if (result?.value?.jwt) {
				TokenService.set(result?.value?.jwt);
			}
			return {
				result: true,
			};
		}
		else {
			throw result?.value;
		}
	};
};

export const useConfirmEmail = () => {
	const confirmCode = apiMethodsAuth.useConfirmCode();
	const getUser = useGetUser();
	const getProfile = useGetProfile();

	return async (payload: EditEmailForm) => {
		const result = await confirmCode(payload);

		if (result?.isRight()) {
			TokenService.set(result.value);
			await getUser();
			await getProfile();
			setEmailNotEdit();
			return {
				result: true,
			};
		}
		else {
			throw result?.value?.message;
		}
	};
};

export const setEmailEdit = () => {
	saveIsEmailEdit(true);
};

export const setEmailNotEdit = () => {
	saveIsEmailEdit(false);
};
