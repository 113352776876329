<script setup lang="ts">
import {
	BaseButton,
	BaseCard,
	BaseCardTitle,
} from '@legacy-studio/library';
import { UiVerificationLabel } from '~/src/shared/ui';
import { useIsAddPaymentEdit, useProfile } from '~/src/features/profile/infrastructure/store';
import {
	setAddPaymentEdit,
} from '~/src/features/profile/core/application/updatePartnerPayment';
import { useGetCategories } from '~/src/features/payment-method/core/application';
import PaymentItems from '~/src/features/profile/ui/Edit/Partner/PaymentItems.vue';
import StopPayments from '~/src/features/profile/ui/Edit/Partner/StopPayments.vue';
import AddPayment from '~/src/features/profile/ui/Edit/Partner/AddPayment.vue';

const user = useProfile();
const isAddPaymentEdit = useIsAddPaymentEdit();

const { pending: pendingCategories } = await useAsyncData(useGetCategories());

const isLoadingAdd = ref(false);
const setNotLoading = () => {
	isLoadingAdd.value = false;
};

const setLoading = () => {
	isLoadingAdd.value = true;
};
</script>

<template>
	<BaseCard :loading="pendingCategories || isLoadingAdd">
		<BaseCardTitle title="Платежная информация" />
		<div class="flex flex-col gap-8">
			<div
				v-if="!user?.paymentInfo.count"
				class="flex flex-col gap-4 md:flex-row items-center justify-between md:gap-[30px]"
			>
				<UiVerificationLabel
					title="Платежный метод: Не добавлен"
					subtitle="Добавьте платежный метод, чтобы получать выплаты по программе"
				/>

				<BaseButton
					v-if="!isAddPaymentEdit"
					color="alternative-dark"
					size="xs"
					button-text="Добавить"
					outline
					class="w-full md:w-auto"
					@click="setAddPaymentEdit"
				/>
			</div>
			<template
				v-else
			>
				<PaymentItems
					:items="user?.paymentInfo.items"
				/>
				<StopPayments v-if="!isAddPaymentEdit" />
			</template>

			<AddPayment
				v-if="isAddPaymentEdit"
				@set-loading="setLoading"
				@set-not-loading="setNotLoading"
			/>
		</div>
	</BaseCard>
</template>
