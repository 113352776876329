<script setup lang="ts">
import {
	BaseFetchWrapper,
} from '@legacy-studio/library';

import Email from '~/src/features/profile/ui/Edit/Email.vue';

const isLoading = ref(false);
const updateLoading = (value) => {
	isLoading.value = value;
};
</script>

<template>
	<BaseFetchWrapper
		class="relative flex flex-col gap-4"
		:pending="isLoading"
	>
		<span class="text-base font-semibold text-white">
			Электронная почта
		</span>

		<Email
			@update-loading="updateLoading"
		/>
	</BaseFetchWrapper>
</template>
