import { apiMethods } from '../../infrastructure/api';
import { useIsUserPartner, useIsUserTrader } from '~/src/features/user';
import { saveProfile } from '~/src/features/profile/infrastructure/store';
import { saveUserLocale } from '~/src/features/user/infrastructure/store';

export const useChangeLocale = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const changeLocaleTrader = apiMethods.useChangeLocaleTrader();
	const changeLocalePartner = apiMethods.useChangeLocalePartner();

	return async (language: string) => {
		let result = null;
		if (isTrader.value) {
			result = await changeLocaleTrader(language);
		}
		else if (isPartner.value) {
			result = await changeLocalePartner(language);
		}

		if (result?.isRight()) {
			saveProfile(result?.value);
			saveUserLocale(result?.value.language);
		}
		else {
			throw result?.value;
		}
	};
};
