<script setup lang="ts">
import {
	BaseCard,
	BaseCardTitle,
	BaseToggle,
	useAsyncState,
} from '@legacy-studio/library';
import { useProfile } from '~/src/features/profile/infrastructure/store';
import { useChangePartnerMailing } from '~/src/features/profile';

const user = useProfile();
const changeMailing = useChangePartnerMailing();
const { execute, isLoading } = useAsyncState(changeMailing);
const onChange = () => {
	execute(!user.value?.allowNewsDist);
};
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Управление рассылками" />

		<div class="flex gap-8 justify-between">
			<div class="flex flex-col gap-1">
				<p class="text-lg font-semibold text-white">
					Я хочу получать рассылку
				</p>

				<p class="text-base font-normal text-gray-400">
					Вы можете настраивать рассылку, чтобы всегда быть в курсе событий
				</p>
			</div>

			<BaseToggle
				:model-value="user?.allowNewsDist"
				@update:model-value="onChange"
			/>
		</div>
	</BaseCard>
</template>
