import { useApi } from '@/src/services/network';
import type { EditPaymentForm, SelectPaymentPayload } from '~/src/features/profile/types';
import { toDomainPartner } from '~/src/features/profile/infrastructure/api/getPartnerProfile';

export const useAddPartnerPayment = () => {
	const api = useApi();

	return async (payload: EditPaymentForm) => {
		return (await api('/Partner/addPayment/', {
			method: 'post',
			body: payload,
		}));
	};
};

const fromDomainChange = (id: string): SelectPaymentPayload => {
	return {
		id,
	};
};

export const useChangePartnerPayment = () => {
	const api = useApi();

	return async (id: string) => {
		return (await api('/Partner/selectPayment/', {
			method: 'post',
			body: fromDomainChange(id),
		}));
	};
};

const fromDomainDisablePayments = (payload: boolean): any => {
	return {
		disable_payments: payload ? '1' : '0',
	};
};

export const useChangePartnerDisablePayments = () => {
	const api = useApi();

	return async (payload: boolean) => {
		return (await api('/Partner/update/', {
			method: 'post',
			body: fromDomainDisablePayments(payload),
		})).mapRight(toDomainPartner);
	};
};
