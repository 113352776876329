import { useUploadAvatarTrader, useUploadAvatarPartner } from './uploadAvatar';
import { useDeleteAvatarTrader, useDeleteAvatarPartner } from './deleteAvatar';
import { useUpdateLoginPartner, useUpdateLoginTrader } from './updateLogin';
import { useChangePasswordTrader, useChangePasswordPartner } from './changePassword';
import { useBlockAccountTrader, useBlockAccountPartner } from './blockAccount';
import { useChangeLocaleTrader, useChangeLocalePartner } from './changeLocale';
import { useChangeEmailPartner, useChangeEmailTrader } from './changeEmail';
import { useGetTraderProfile } from './getTraderProfile';
import { useUpdateTrader } from './updateTraderProfile';
import { useUpdatePartnerPersonal } from './updatePartnerProfile';
import { useAddTraderVerification } from './addTraderVerification';
import { useGetPartnerProfile } from '~/src/features/profile/infrastructure/api/getPartnerProfile';
import { useChangePartnerTraffic } from '~/src/features/profile/infrastructure/api/changePartnerTraffic';
import { useChangePartnerMailing } from '~/src/features/profile/infrastructure/api/changePartnerMailing';
import {
	useAddPartnerPayment,
	useChangePartnerDisablePayments,
	useChangePartnerPayment,
} from '~/src/features/profile/infrastructure/api/updatePartnerPayment';

export const apiMethods = {
	useAddPartnerPayment,
	useUploadAvatarPartner,
	useUploadAvatarTrader,
	useDeleteAvatarTrader,
	useDeleteAvatarPartner,
	useUpdateLoginTrader,
	useUpdateLoginPartner,
	useChangePasswordTrader,
	useChangePasswordPartner,
	useChangePartnerMailing,
	useBlockAccountTrader,
	useBlockAccountPartner,
	useChangeLocaleTrader,
	useChangeLocalePartner,
	useChangeEmailTrader,
	useChangeEmailPartner,
	useGetTraderProfile,
	useGetPartnerProfile,
	useUpdateTrader,
	useAddTraderVerification,
	useUpdatePartnerPersonal,
	useChangePartnerTraffic,
	useChangePartnerPayment,
	useChangePartnerDisablePayments,
};
