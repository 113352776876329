import { useApi } from '@/src/services/network';

export const useBlockAccountTrader = () => {
	const api = useApi();

	return async () =>
		await api('/Trader/blockAccount/', {
			method: 'post',
		});
};

export const useBlockAccountPartner = () => {
	const api = useApi();

	return async () =>
		await api('/Partner/blockAccount/', {
			method: 'post',
		});
};
