import type { ChangePasswordForm } from '../../core/types';
import { useApi } from '@/src/services/network';

export const useChangePasswordTrader = () => {
	const api = useApi();

	return async (payload: ChangePasswordForm) =>
		await api('/Trader/changePassword/', {
			method: 'post',
			body: {
				password: payload.password,
				new_password: payload.newPassword,
				new_password_confirmation: payload.newPasswordConfirmation,
			},
		});
};

export const useChangePasswordPartner = () => {
	const api = useApi();

	return async (payload: ChangePasswordForm) =>
		await api('/Partner/changePassword/', {
			method: 'post',
			body: {
				password: payload.password,
				new_password: payload.newPassword,
				new_password_confirmation: payload.newPasswordConfirmation,
			},
		});
};
