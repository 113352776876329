import {
	readActiveInvoice,
	readInvoices,
	saveActiveInvoice,
} from '../../infrastructure/store';
import type { InvoiceId } from '../../types';

export const useChangeActiveInvoice = () => {
	const activeInvoiceCookie = useCookie<InvoiceId | null>('activeInvoiceId');

	return (id: InvoiceId) => {
		const current = readActiveInvoice();

		if (current?.id === id) {
			return;
		}

		const invoices = readInvoices();
		const maybeInvoice = invoices.find(i => i.id === id);

		if (maybeInvoice) {
			saveActiveInvoice(maybeInvoice);

			activeInvoiceCookie.value = maybeInvoice.id;
		}
	};
};
