<script setup lang="ts">
import { BaseButton, BaseFetchWrapper, useAsyncState } from '@legacy-studio/library';
import { useGetCategories } from '~/src/features/payment-method/core/application';
import { usePaymentMethodsCategories } from '~/src/features/payment-method/infrastructure/store';
import {
	setAddPaymentEdit,
	useChangePartnerPayment,
} from '~/src/features/profile/core/application/updatePartnerPayment';
import { useIsAddPaymentEdit } from '~/src/features/profile/infrastructure/store';

interface Props {
	items: any;
}

const isAddPaymentEdit = useIsAddPaymentEdit();

const props = withDefaults(defineProps<Props>(), {
	items: [],
});

await useAsyncData(useGetCategories());
const categories = usePaymentMethodsCategories();

const getValidationName = (paymentTypeId, paymentId) => {
	const category = categories.value?.find(category => category.id === paymentTypeId);

	const item = category?.items.find(item => item.id === paymentId);

	return item?.validation?.name || '';
};

const { execute, isLoading } = useAsyncState(useChangePartnerPayment());

const onClickChange = async (id) => {
	await execute(id);
};
</script>

<template>
	<BaseFetchWrapper :pending="isLoading">
		<div
			class="flex flex-col gap-4"
		>
			<div
				v-for="payment in items"
				:key="payment.id"
				class="p-4 border border-gray-600 rounded-lg flex gap-4 cursor-pointer"
				:class="{ 'bg-gray-700': payment.isSelected }"
				@click="() => onClickChange(payment.id)"
			>
				<span
					class="my-1 border border-gray-600 rounded-full min-w-4 h-4 bg-gray-700 flex items-center justify-center"
					:class="{ 'border-primary-300 border-[3.5px] bg-gray-800': payment.isSelected }"
				/>
				<div class="flex flex-col gap-1">
					<div class="flex gap-1">
						<span class="text-white text-base font-semibold">
							{{ payment.paymentType.name }}:
						</span>
						<span class="text-gray-400 leading-tight text-sm font-medium pt-[4.5px]">
							{{ payment.paymentSystem.name }}
						</span>
					</div>
					<div class="flex gap-1">
						<span class="text-white text-base font-semibold">
							{{ getValidationName(payment.paymentType.id, payment.paymentSystem.id) }}:
						</span>
						<span class="text-gray-400 leading-tight text-sm font-medium pt-[4.5px]">
							{{ payment.account }}
						</span>
					</div>
					<span class="text-sm font-normal text-gray-400">
						Для изменения реквизитов платежной системы свяжитесь со службой <br> поддержки:
						<!--					<a -->
						<!--						href="mailto:support@bbroker.com" -->
						<!--						class="underline text-sm font-normal text-primary-300" -->
						<!--						@click.stop -->
						<!--					> -->
						<!--						support@bbroker.com -->
						<!--					</a> -->
						<span class="underline text-sm font-normal text-primary-300">
							support@bbroker.com
						</span>
					</span>
				</div>
			</div>
			<BaseButton
				v-if="!isAddPaymentEdit"
				color="alternative-dark"
				size="xs"
				button-text="Добавить"
				outline
				class="w-full md:w-max"
				@click="setAddPaymentEdit"
			/>
		</div>
	</BaseFetchWrapper>
</template>
