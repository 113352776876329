import { apiMethods } from '../../infrastructure/api';

export const useGetCities = () => {
	const getCities = apiMethods.useGetCities();

	return async (countryСode, query) => {
		const result = await getCities(countryСode, query);
		if (result.isRight()) {
			return result.value;
		}
	};
};
