import { useApi } from '@/src/services/network';

export const useDeleteAvatarTrader = () => {
	const api = useApi();

	return async () => {
		return (await api('/Trader/deletePhoto/', {
			method: 'post',
		}));
	};
};

export const useDeleteAvatarPartner = () => {
	const api = useApi();

	return async () => {
		return (await api('/Partner/deletePhoto/', {
			method: 'post',
		}));
	};
};
