<script setup lang="ts">
import { BaseIcon, type BaseIconName } from '@legacy-studio/library';

const props = defineProps<{
	status?: string;
	title: string;
	subtitle: string;
}>();

const iconName = computed<BaseIconName>(() => {
	switch (props.status) {
		case 'verified':
			return 'base--fill--check-circle';
		default:
			return 'base--fill--exclamation-circle';
	}
});
</script>

<template>
	<div class="flex gap-2">
		<BaseIcon
			v-if="status"
			:name="iconName"
			class="text-[24px] text-gray-400 min-w-6 max-w-6"
			:class="{
				'text-green-400': status === 'verified',
				'text-red-400': status === 'unverified',
				'text-gray-400': status === 'pending',
			}"
		/>

		<div class="flex flex-col gap-1">
			<span class="text-base font-semibold text-white">
				{{ title }}
			</span>

			<span
				class="text-sm font-normal text-gray-400"
				v-html="subtitle"
			/>
		</div>
	</div>
</template>
