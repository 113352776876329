<script setup lang="ts">
import {
	BaseCard,
	BaseTextField,
	BaseCardTitle,
	useAsyncState,
	BaseButton,
	getTextFieldBinds,
} from '@legacy-studio/library';
import { useForm } from 'vee-validate';
import { editLoginSchema } from '../../core/validation';
import {
	setLoginEdit,
	setLoginNotEdit,
	useUpdateLogin,
} from '../../core/application';
import type { EditLoginForm } from '../../types';
import { useIsLoginEdit, useProfile } from '../../infrastructure/store';
import EditForm from './Form.vue';

const user = useProfile();
const isLoginEdit = useIsLoginEdit();

const { defineField, handleSubmit } = useForm<EditLoginForm>({
	validationSchema: editLoginSchema,
	initialValues: {
		login: user.value.login || '',
	},
});
const [newLogin, newLoginProps] = defineField('login', getTextFieldBinds);

const { execute, isLoading } = useAsyncState(useUpdateLogin());

const onSubmit = handleSubmit(execute);
</script>

<template>
	<BaseCard
		v-if="user"
		:loading="isLoading"
	>
		<BaseCardTitle title="Логин" />

		<div
			class="flex flex-col justify-between gap-4 md:flex-row md:items-center md:pb-4"
		>
			<div class="flex flex-col gap-1">
				<span class="text-base font-semibold text-white">
					{{ user.login }}
				</span>

				<span class="text-sm font-normal text-gray-400">
					Вы можете сменить логин, которой будет отображаться в интерфейсе
				</span>
			</div>

			<BaseButton
				v-if="!isLoginEdit"
				color="alternative-dark"
				size="xs"
				button-text="Изменить"
				outline
				class="w-full md:w-auto"
				@click="setLoginEdit"
			/>
		</div>

		<EditForm
			v-if="isLoginEdit"
			@submit="onSubmit"
			@cancel="setLoginNotEdit"
		>
			<BaseTextField
				v-model="newLogin"
				v-bind="newLoginProps"
				placeholder="Введите новый логин"
				label="Новый логин"
			/>
		</EditForm>
	</BaseCard>
</template>
