import { apiMethods } from '../../infrastructure/api';
import { useGetUser, useIsUserPartner, useIsUserTrader } from '@/src/features/user';

export const useDeleteAvatar = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const deleteAvatarTrader = apiMethods.useDeleteAvatarTrader();
	const deleteAvatarPartner = apiMethods.useDeleteAvatarPartner();

	const getUser = useGetUser();

	return async () => {
		let result = null;
		if (isTrader.value) {
			result = await deleteAvatarTrader();
		}
		else if (isPartner.value) {
			result = await deleteAvatarPartner();
		}

		if (result?.isRight()) {
			await getUser();
		}
		else {
			throw result.value;
		}
	};
};
